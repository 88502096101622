import React, { useEffect } from 'react';
import {
  BasicModalDialog,
  BodyM,
  FormFilledAnimation,
  Heading3,
  PrimaryButton,
  SecondaryButton,
} from '@laasco/ui-lib';
import { CloseMessage } from '../../i18n/commonMessages';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { useRequestMagicLinkMutation } from '../../generated';
import { useRouter } from 'next/router';
import { TextField } from '../../components/form';
import styled from '@emotion/styled';

export interface RequestMagicLinkDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  returnTo: string;
}

const magicLinkFormSchema = yup.object({
  email: yup.string().email().required(),
});

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 64px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const RequestMagicLinkDialog = ({
  isOpen,
  setIsOpen,
  returnTo,
}: RequestMagicLinkDialogProps) => {
  const [requestMagicLink] = useRequestMagicLinkMutation();
  const router = useRouter();
  useEffect(() => {
    const id = setInterval(() => {}, 1000);
    return () => clearInterval(id);
  });

  const intl = useIntl();
  const [isSuccess, setIsSuccess] = React.useState(false);

  return (
    <BasicModalDialog
      onBackdropClick={() => setIsOpen(false)}
      onCloseClick={() => setIsOpen(false)}
      closeButtonLabel={<CloseMessage />}
      size={'large'}
      isOpen={isOpen}
      headerContent={
        <Heading3>
          <FormattedMessage defaultMessage={'Sign in using magic link'} />
        </Heading3>
      }
    >
      <Formik
        validationSchema={magicLinkFormSchema}
        isInitialValid={false}
        initialValues={{ email: '' }}
        onSubmit={async (params, helpers) => {
          const result = await requestMagicLink({
            variables: {
              email: params.email,
              locale: router.locale!,
              returnToPath: returnTo || '/',
            },
          });
          if (!result?.data?.requestMagicLink?.canSend) {
            helpers.setErrors({
              email: intl.formatMessage({
                defaultMessage: 'Your account is not found or active',
              }),
            });
            return;
          }
          if (result?.data?.requestMagicLink?.isThrottled) {
            helpers.setErrors({
              email: intl.formatMessage(
                {
                  defaultMessage:
                    'Too many attempts, please try again at {time}',
                },
                {
                  time: (
                    <FormattedTime
                      value={result.data.requestMagicLink.nextRequestAllowedAt!}
                      timeStyle={'short'}
                    />
                  ),
                },
              ) as any,
            });
            return;
          }
          setIsSuccess(true);
        }}
      >
        {(formik) => {
          if (isSuccess) {
            return (
              <SuccessContainer>
                <div style={{ maxWidth: 300 }}>
                  <FormFilledAnimation />
                </div>
                <BodyM>
                  <FormattedMessage
                    defaultMessage={
                      'Email with sign in instructions was sent to <strong>{email}</strong>'
                    }
                    values={{ email: formik.values.email }}
                  />
                </BodyM>
                <SecondaryButton
                  onClick={() => {
                    formik.resetForm();
                    setIsSuccess(false);
                  }}
                >
                  <FormattedMessage defaultMessage={'Use another email'} />
                </SecondaryButton>
              </SuccessContainer>
            );
          }

          return (
            <StyledForm>
              <TextField
                label={<FormattedMessage defaultMessage={'Enter your email'} />}
                name={'email'}
              />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
              >
                <PrimaryButton
                  type="submit"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  <FormattedMessage defaultMessage={'Submit'} />
                </PrimaryButton>
              </div>
            </StyledForm>
          );
        }}
      </Formik>
    </BasicModalDialog>
  );
};
