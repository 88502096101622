import React from 'react';
import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  NextPage,
  Redirect,
} from 'next';
import { LoginView, LoginViewProps } from '../../views/LoginView';
import { LoginConfigurationDataFragment } from '../../generated';
import Cookies from 'cookies';
import * as acceptParser from 'accept-language-parser';
import { pathWithLocale } from '../../common/pathWithLocale';
import { setNextLocaleCookieForContext } from '../../common/setNextLocaleCookieForContext';
import { GraphQLProvider } from '../../provider/GraphQLProvider';
import { getLoginConfigurationForHostName } from '../../common/getLoginConfigurationForHostName';

const redirectToLocaleIfNeeded = (
  ctx: GetServerSidePropsContext,
  locale: string,
): Redirect | null => {
  if (ctx.locale != locale) {
    setNextLocaleCookieForContext(ctx, locale);
    return {
      permanent: false,
      destination: pathWithLocale(locale, ctx.resolvedUrl!),
    };
  }
  return null;
};

const getLocaleRedirect = (
  ctx: GetServerSidePropsContext,
  config: LoginConfigurationDataFragment,
) => {
  const cookies = new Cookies(ctx.req, ctx.res);
  const localeCookie = cookies.get('NEXT_LOCALE');

  const supportedLanguages = config.availableLanguages
    .filter((l) => l)
    .map((l) => l.substr(0, 2).toLowerCase());

  // Locale has already been set, don't set it again, just redirect to it
  if (localeCookie && supportedLanguages.includes(localeCookie)) {
    return redirectToLocaleIfNeeded(ctx, localeCookie);
  }

  const defaultLanguage = supportedLanguages[0] || 'en';

  // If No accept language header present -> redirect to default language if needed
  if (!ctx.req.headers['accept-language']) {
    return redirectToLocaleIfNeeded(ctx, defaultLanguage);
  }

  // Redirect to user's preferred language if one exists
  const language =
    acceptParser.pick(supportedLanguages, ctx.req.headers['accept-language']) ||
    defaultLanguage;

  return redirectToLocaleIfNeeded(ctx, language);
};

const getLoginPageProps: GetServerSideProps<LoginViewProps> = async (ctx) => {
  const config = await getLoginConfigurationForHostName(ctx.req.headers.host!);

  if (!config) {
    return { notFound: true };
  }

  const localeRedirect = getLocaleRedirect(ctx, config);
  if (localeRedirect) {
    return { redirect: localeRedirect };
  }

  return {
    props: {
      loginMethods: config.availableLoginMethods,
      availableLanguages: config.availableLanguages.map((l) => l.slice(0, 2)),
      ...(config.loginLogoUrl && { logoUrl: config.loginLogoUrl }),
    },
  };
};

export const getServerSideProps: GetServerSideProps = getLoginPageProps;

export const Index: NextPage<LoginViewProps> = (props) => {
  return (
    <GraphQLProvider unAuthenticated>
      <LoginView {...props} />
    </GraphQLProvider>
  );
};
export default Index;
