import { LiteSelect } from '@laasco/ui-lib';
import React, { useCallback } from 'react';
import { getLangNameFromCode } from 'language-name-map';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';

export interface LanguageSelectViewProps {
  availableLanguages: string[];
}
export const LanguageSelectView = (props: LanguageSelectViewProps) => {
  const [_cookie, setCookie] = useCookies();
  const router = useRouter();

  const availableLanguages =
    props.availableLanguages
      ?.map((value) => ({
        value,
        label: getLangNameFromCode(value)?.native,
      }))
      .sort((a, b) => a.value.localeCompare(b.value)) || [];

  const setLocale = useCallback(
    (locale: string) => {
      setCookie('NEXT_LOCALE', locale, {
        path: '/',
        maxAge: 3600 * 24 * 365 * 1000,
      });
      router.replace(`${router.asPath}`, undefined, { locale });
    },
    [router, setCookie],
  );

  return (
    <LiteSelect
      placement={'bottom-start'}
      items={availableLanguages}
      value={router.locale!}
      onChange={setLocale}
      renderSelected={(item) => item.value.toUpperCase()}
      renderOption={(item) => `${item.value.toUpperCase()} - ${item.label}`}
    />
  );
};
