import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import qs from 'qs';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { getImageId } from '../../common/getImageId';
import { MicrosoftWindow } from '@laasco/ui-lib/icons';
import {
  BodyM,
  bodySStyles,
  HeadingL,
  headingMStyles,
  headingSStyles,
  LaasLink,
  LoginPageLayout,
  PrimaryButton,
  Spacer,
} from '@laasco/ui-lib';
import Image from 'next/image';
import GoogleButton from 'react-google-button';
import { mediaQuery } from '@laasco/ui-lib/constants';
import oktaLogo from './okta-logo.png';
import { RequestMagicLinkDialog } from './RequestMagicLinkDialog';
import { LanguageSelectView } from './LanguageSelectView';

export interface LoginViewProps {
  loginMethods: string[];
  availableLanguages: string[];
  logoUrl?: string;
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Header = styled(HeadingL)`
  white-space: nowrap;
  @media screen and ${mediaQuery.medium} {
    ${headingMStyles};
  }
  @media screen and ${mediaQuery.small} {
    ${headingSStyles};
  }
`;

const LoginButton = styled(PrimaryButton)`
  min-width: 188px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const UnStyledLink = styled.a`
  text-decoration: none;
`;

const LoginHref = styled(LaasLink)`
  display: inline;
  @media screen and ${mediaQuery.small} {
    display: block;
  }
`;

const ssoLoginMethodOrder: { [k: string]: number } = {
  azure: 0,
  google: 1,
};

type LoginLink = React.FunctionComponent<
  React.PropsWithChildren<{ href: string }>
>;

const AzureLogin: LoginLink = (props) => {
  return (
    <UnStyledLink href={props.href}>
      <LoginButton onClick={() => {}} leftIcon={<MicrosoftWindow />}>
        <FormattedMessage
          defaultMessage="Sign in with Microsoft"
          description="Sing the user in with microsoft azure AD button"
        />
      </LoginButton>
    </UnStyledLink>
  );
};

const GoogleLogin: LoginLink = (props) => {
  return (
    <UnStyledLink href={props.href}>
      <GoogleButton type="light" />
    </UnStyledLink>
  );
};

const Auth0Login: LoginLink = (props) => {
  return (
    <UnStyledLink href={props.href}>
      <LoginButton onClick={() => {}}>
        <FormattedMessage
          defaultMessage="Log in"
          description={'Log in with auth0'}
        />
      </LoginButton>
    </UnStyledLink>
  );
};

const OktaButton = styled(LoginButton)`
  > :nth-child(1) {
    top: -2px;
  }
  gap: 16px;
`;

const OktaLogin: LoginLink = (props) => {
  return (
    <UnStyledLink href={props.href}>
      <OktaButton onClick={() => {}}>
        <Image
          unoptimized
          {...oktaLogo}
          width={50}
          height={17}
          alt={'Okta logo'}
        />
        <FormattedMessage
          defaultMessage="Sign in with Okta"
          description={'Sign in with Okta button'}
        />
      </OktaButton>
    </UnStyledLink>
  );
};

const LoginBodyText = styled(BodyM)`
  @media screen and ${mediaQuery.medium} {
    margin: 0 42px;
  }
  @media screen and ${mediaQuery.small} {
    ${bodySStyles};
    margin: 0 0px;
  }
`;

const loginComponentMap: { [k: string]: LoginLink } = {
  azure: AzureLogin,
  google: GoogleLogin,
  okta: OktaLogin,
};

const ssoLoginMethods = new Set(Object.keys(ssoLoginMethodOrder));

const getSSOLoginMethodsOrdered = (loginMethods: string[]) =>
  _.sortBy(
    loginMethods.filter((m) => ssoLoginMethods.has(m)),
    (lm) => ssoLoginMethodOrder[lm],
  );

const ImageContainer = styled.div`
  max-width: 160px;
  width: 160px;
  height: 68px;
  position: relative;
`;

const StyledImage = styled(Image)`
  object-fit: contain;
`;

const NonSsoMethodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const LoginView: React.FunctionComponent<
  React.PropsWithChildren<LoginViewProps>
> = (props) => {
  const router = useRouter();
  const returnTo = router.query['return_to'] as string;

  const ssoLoginMethods = getSSOLoginMethodsOrdered(props.loginMethods);
  const hasSSOLoginMethods = ssoLoginMethods.length > 0;
  const hasMagicLinkLoginMethod = props.loginMethods.includes('magic-token');

  const [isMagicTokenDialogOpen, setIsMagicTokenDialogOpen] = useState(false);

  const loginUrlForMethod = (method: string) =>
    `/api/login?${qs.stringify({
      method,
      return_to: returnTo,
    })}`;

  return (
    <>
      <LoginPageLayout
        topLeftContent={
          <LanguageSelectView availableLanguages={props.availableLanguages} />
        }
      >
        <ContentContainer>
          {props.logoUrl ? (
            <ImageContainer>
              <StyledImage fill alt="logo" src={getImageId(props.logoUrl)!} />
            </ImageContainer>
          ) : null}
          <Spacer height={48} mediumHeight={16} />
          <Header>
            <FormattedMessage
              description={'Login page heading text'}
              defaultMessage="Are you ready?"
            />
          </Header>
          <Spacer height={24} />
          <LoginBodyText>
            <FormattedMessage
              description={'Login page body text'}
              defaultMessage="Log in to LaaS and go towards companion, health and balance. Now."
            />
          </LoginBodyText>
          <Spacer height={48} mediumHeight={32} smallHeight={24} />
          {hasSSOLoginMethods ? (
            ssoLoginMethods.map((method, idx) => {
              const Component = loginComponentMap[method];
              if (!Component) {
                return 'No component found for: ' + method;
              }
              return (
                <Fragment key={method}>
                  {idx === 0 ? null : <Spacer height={24} smallHeight={16} />}
                  <Component key={method} href={loginUrlForMethod(method)} />
                </Fragment>
              );
            })
          ) : (
            <NonSsoMethodsContainer>
              <Auth0Login href={loginUrlForMethod('auth0')} />
              {hasMagicLinkLoginMethod && (
                <>
                  <BodyM>
                    <FormattedMessage defaultMessage={'Or'} />
                  </BodyM>
                  <PrimaryButton
                    onClick={() => setIsMagicTokenDialogOpen(true)}
                  >
                    <FormattedMessage
                      description={'Login page magic link button'}
                      defaultMessage={'Sign in without password'}
                    />{' '}
                  </PrimaryButton>
                </>
              )}
            </NonSsoMethodsContainer>
          )}
          {hasSSOLoginMethods ? (
            <>
              <Spacer height={32} />
              <BodyM>
                <FormattedMessage
                  description={'Login page external provider question'}
                  defaultMessage={'Are you an external provider?'}
                />{' '}
                <LoginHref key={'auth0'} href={loginUrlForMethod('auth0')}>
                  <FormattedMessage
                    description={'External provider login link content'}
                    defaultMessage={'Log in here'}
                  />
                </LoginHref>
                {hasMagicLinkLoginMethod && (
                  <>
                    {' '}
                    <FormattedMessage defaultMessage={'or'} />{' '}
                    <LoginHref
                      key={'magiclink'}
                      onClick={() => setIsMagicTokenDialogOpen(true)}
                    >
                      <FormattedMessage
                        description={'External provider magic link'}
                        defaultMessage={'Sign in without a password'}
                      />
                    </LoginHref>
                  </>
                )}
              </BodyM>
            </>
          ) : null}
        </ContentContainer>
      </LoginPageLayout>
      <RequestMagicLinkDialog
        isOpen={isMagicTokenDialogOpen}
        setIsOpen={setIsMagicTokenDialogOpen}
        returnTo={returnTo}
      />
    </>
  );
};
